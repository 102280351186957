import * as React from 'react'

import { Box, Grid, Link, Stack, Typography } from '@mui/material'
import { StaticImage } from 'gatsby-plugin-image'

import BookButton from '../components/book_button'
import CursiveTypography from '../components/cursive_typography'
import NavBar from '../components/nav_bar'
import { Parallax, ParallaxGroup, ParallaxLayer } from '../components/parallax'
import SeoHeaders from '../components/seo_headers'
import SocialButtons from '../components/social_buttons'
import LandingFooter from '../sections/landing/footer'

import type { GridProps, StackProps } from '@mui/material'

const TextContainer = ({ children }: { children: StackProps['children'] }) => (
  <Grid
    item
    xs={12}
    md={6}
  >
    <Stack
      spacing={4}
      alignItems='center'
      justifyContent='center'
      maxWidth='480px'
      height='100%'
      mx='auto'
      px={3}
      py={6}
      sx={(theme) => ({
        [theme.breakpoints.up('md')]: {
          alignItems: 'flex-start',
        },
      })}
    >
      {children}
    </Stack>
  </Grid>
)

const ImageContainer = ({ children }: { children: GridProps['children'] }) => (
  <Grid
    item
    xs={12}
    md={6}
  >
    {children}
  </Grid>
)

const MuseumPage = () => {
  return (
    <React.Fragment>
      <SeoHeaders
        title='Museo'
        description={(
          "Dentro del Buque Insignia se encuentra el Museo Ocean Pacific's, un "
          + 'espacio dedicado a la cultura e historia marítima donde se exhiben '
          + 'elementos de alto valor histórico que durante décadas se han atesorado.'
        )}
      />
      <NavBar />
      <SocialButtons />
      <BookButton />
      <Parallax>
        <ParallaxGroup sx={{ minHeight: '630px' }}>
          <ParallaxLayer depth={-1}>
            <StaticImage
              src='../images/museum/6X3A1771-7.jpg'
              alt='Salón Cosca'
              layout='fullWidth'
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
              }}
              quality={90}
              height={2000}
            />
          </ParallaxLayer>
          <ParallaxLayer
            sx={{
              background: 'linear-gradient(to top, rgba(30, 30, 30, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%)',
              color: 'background.default',
            }}
          >
            <Stack
              py={6}
              px={3}
              spacing={4}
              alignItems='center'
              justifyContent='center'
              height='100%'
              maxWidth='md'
              mx='auto'
              my='auto'
            >
              <CursiveTypography variant='h1'>
                Museo Ocean Pacific&apos;s
              </CursiveTypography>
              <Typography
                textAlign='justify'
              >
                Dentro del Buque Insignia se encuentra el Museo Ocean
                Pacific&apos;s, un espacio dedicado a la cultura e historia
                marítima donde se exhiben elementos de alto valor histórico que
                durante décadas se han atesorado.
              </Typography>
              <Typography
                textAlign='justify'
              >
                Para visitas de delegaciones o en horarios espaciales comunícate
                con nosotros.
              </Typography>
              <Box sx={{ border: 1, p: 1, minWidth: '75%' }}>
                <Typography
                  textAlign='center'
                  sx={{ pb: 2 }}
                >
                  <strong>Dirección</strong>
                  <br />
                  Ocean Pacific&apos;s Buque Insignia
                  <br />
                  Agustinas 2267 &ndash; Santiago Centro
                </Typography>
                <Typography
                  textAlign='center'
                  sx={{ pb: 2 }}
                >
                  <strong>Horario</strong>
                  <br />
                  Lunes a Domingo de 12:00 a 23:00
                </Typography>
                <Typography
                  textAlign='center'
                >
                  <strong>Contacto</strong>
                  <br />
                  <Link
                    target='_blank'
                    rel='noopener noreferrer'
                    href='mailto:museo@oceanpacifics.cl'
                    sx={(theme) => ({
                      fontWeight: 500,
                      color: theme.palette.common.white,
                      textDecorationColor: theme.palette.common.white,
                    })}
                  >
                    museo@oceanpacifics.cl
                  </Link>
                </Typography>
              </Box>
            </Stack>
          </ParallaxLayer>
        </ParallaxGroup>
        <ParallaxGroup
          sx={(theme) => ({
            zIndex: 2,
            minHeight: '1940px',
            [theme.breakpoints.up('sm')]: {
              minHeight: '1850px',
            },
            [theme.breakpoints.up('md')]: {
              minHeight: '1000px',
            },
          })}
        >
          <ParallaxLayer
            bgcolor='background.default'
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Grid
              maxWidth='xl'
              mx='auto'
              container
            >
              <TextContainer>
                <CursiveTypography variant='h2'>
                  Exposición
                </CursiveTypography>
                <Typography textAlign='justify'>
                  El Museo presenta una variada selección de elementos náuticos
                  que relatan la historia y el patrimonio del Océano. Se exhiben
                  reproducciones de fauna marina en tamaño natural, barcos
                  históricos a escala y elementos de navegación, además se
                  presentan diversas piezas de la historia del Restaurant.
                </Typography>
              </TextContainer>
              <ImageContainer>
                <StaticImage
                  src='../images/museum/6X3A4710-3-2.jpg'
                  alt={'Exposición fósiles'}
                  objectFit='cover'
                  quality={90}
                  style={{ height: '420px' }}
                  width={900}
                />
              </ImageContainer>
              <ImageContainer>
                <StaticImage
                  src='../images/museum/6X3A2131-4-2.jpg'
                  alt={'Ancla oxidada y elementos de navegación'}
                  objectFit='cover'
                  quality={90}
                  style={{ height: '580px' }}
                  width={900}
                />
              </ImageContainer>
              <TextContainer>
                <CursiveTypography variant='h2'>
                  Historia
                </CursiveTypography>
                <Typography textAlign='justify'>
                  Ocean Pacific&apos;s Restaurant fue fundado en 1989 y durante
                  décadas atesoró elementos náuticos para cautivar a sus
                  comensales.
                </Typography>
                <Typography textAlign='justify'>
                  En sus inicios los comensales hicieron pequeñas donaciones de
                  elementos hasta formar grandes acervos.
                </Typography>
                <Typography textAlign='justify'>
                  En 2019 el Restaurant sufrió un incendio y la mayoría de sus
                  elementos resultaron destruidos o irreparablemente dañados.
                </Typography>
                <Typography textAlign='justify'>
                  La muestra debió crearse una vez más, gracias a donaciones y
                  la labor realizada, se logró nuevamente presentar elementos
                  náuticos de gran valor patrimonial.
                </Typography>
              </TextContainer>
            </Grid>
          </ParallaxLayer>
        </ParallaxGroup>
        <ParallaxGroup sx={{ minHeight: '550px' }}>
          <ParallaxLayer depth={-1}>
            <StaticImage
              src='../images/museum/6X3A2137-4-2.jpg'
              alt='Escafandra y propulsor antiguos'
              layout='fullWidth'
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
              }}
              quality={90}
              height={2000}
            />
          </ParallaxLayer>
          <ParallaxLayer
            sx={{
              background: 'linear-gradient(to top, rgba(30, 30, 30, 0.7) 0%, rgba(0, 0, 0, 0.7) 100%)',
              color: 'background.default',
            }}
          >
            <Stack
              py={6}
              px={3}
              spacing={4}
              alignItems='center'
              justifyContent='center'
              height='100%'
              maxWidth='md'
              mx='auto'
              my='auto'
            >
              <CursiveTypography variant='h2'>
                Misión, Visión y Valores
              </CursiveTypography>
              <Typography
                textAlign='justify'
                width='100%'
              >
                <strong>Misión</strong>
                <br />
                Promover la difusión de la cultura marítima de forma innovadora
                y participativa, mediante la investigación y conservación del
                patrimonio material e inmaterial del Océano y a su vez ser los
                custodios de la historia de Ocean Pacific&apos;s Restaurant.
              </Typography>
              <Typography
                textAlign='justify'
                width='100%'
              >
                <strong>Visión</strong>
                <br />
                Ser el mayor referente de la historia y el patrimonio marítimo
                en la ciudad de Santiago, además de un faro para el desarrollo
                sustentable y el cuidado del medio ambiente.
              </Typography>
              <Typography
                textAlign='justify'
                width='100%'
              >
                <strong>Valores</strong>
                <br />
                Pluralismo, respeto y rigurosidad científica.
              </Typography>
            </Stack>
          </ParallaxLayer>
        </ParallaxGroup>
        <LandingFooter />
      </Parallax>
    </React.Fragment>
  )
}

export default MuseumPage
